var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"candidate__additional-wrapper"},[_c('showing-provider',{scopedSlots:_vm._u([{key:"default",fn:function(showingProvider){return [(_vm.logs)?_c('send-log-field',{attrs:{"ID":_vm.item.ID},on:{"send":showingProvider.toggle},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var sendLog = ref.sendLog;
var log = ref.log;
var updateInput = ref.updateInput;
var updateLog = ref.updateLog;
return [_c('full-screen-modal',{attrs:{"title":"Add log"},on:{"input":showingProvider.updateShowing},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{staticClass:"btn-floating",attrs:{"color":"white","fab":""},on:{"click":showingProvider.toggle}},[_c('v-icon',[_vm._v("mdi-text-box-plus-outline")])],1)]},proxy:true}],null,true),model:{value:(showingProvider.showing),callback:function ($$v) {_vm.$set(showingProvider, "showing", $$v)},expression:"showingProvider.showing"}},[_c('div',[_c('div',{staticClass:"appmodal-title"},[_vm._v(_vm._s(_vm.item.candidateName))]),_c('div',{staticClass:"appmodal-descr"},[_vm._v(_vm._s(_vm.item.exam.location)),_c('br'),_vm._v(_vm._s(_vm.item.exam.examDate))]),_c('app-textarea',{attrs:{"value":log,"hide-details":"","placeholer":"Log..."},on:{"input":updateLog}}),_c('app-btn',{attrs:{"color":"primary","v2":"","font-size":"16px","block":"","loading":loading},nativeOn:{"click":function($event){return sendLog($event)}}},[_vm._v("SEND")])],1)])]}}],null,true)}):_vm._e()]}}])}),(_vm.logs)?_c('logs',{ref:"log",staticClass:"student-log",attrs:{"value":_vm.logsShowing,"model":_vm.logs,"dark":false},on:{"click:loadMore":_vm.loadLogsMore},scopedSlots:_vm._u([{key:"header",fn:function(props){return undefined}},{key:"candidateName",fn:function(ref){
var candidate = ref.candidate;
var fullName = ref.fullName;
return [_c('span',{staticClass:"link"},[_vm._v(_vm._s(fullName))]),_c('span',{staticClass:"link"},[_vm._v("- "+_vm._s(candidate.number)+" ")])]}}],null,false,357892720)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }