<template lang="pug">
div.candidate__additional-wrapper
  showing-provider(v-slot="showingProvider")
    send-log-field(:ID="item.ID" v-if="logs" @send="showingProvider.toggle")
      template(#default="{loading, sendLog, log, updateInput, updateLog}")
        full-screen-modal(
          v-model="showingProvider.showing"
          @input="showingProvider.updateShowing"
          title="Add log"
        )
          template(#activator)
            v-btn(
              color="white" fab
              @click="showingProvider.toggle"
            ).btn-floating
              v-icon mdi-text-box-plus-outline
          div 
            .appmodal-title {{ item.candidateName }}
            div.appmodal-descr
              | {{ item.exam.location }}
              br
              | {{ item.exam.examDate }}
            app-textarea(
            :value="log" @input="updateLog" hide-details placeholer="Log..."
            )
            app-btn(color="primary" v2 font-size="16px" block @click.native="sendLog"  :loading="loading") SEND
  <!-- send-log-field(:ID="item.ID" v-if="logs") -->
  logs(:value="logsShowing" v-if="logs" @click:loadMore="loadLogsMore" :model="logs" ref="log" :dark="false").student-log
    template(v-slot:header="props")
      <!-- logsTabs(:model="props.model" :loadData="props.loadData" :getDefaultPaginationParams="props.getDefaultPaginationParams") -->
    template(v-slot:candidateName="{candidate, fullName}")
      span.link {{ fullName }}
      span.link - {{ candidate.number }}  

  
</template>

<script>
import { mapGetters } from 'vuex'
import Logs from '@/app/admin/components/Log.vue'
import sendLogField from "@/app/admin/modules/candidates/components/item/CandidateSendLogField.vue"
import logsTabs from "@/app/admin/modules/candidates/components/item/CandidateLogTab.vue"

import { LOG_PAGE } from '@/util/const'
import CandidatesLogModel from '../../../candidates/core/models/candidatesLogModel'
import FullScreenModal from "./CourseFullScreenModal.vue"
import ShowingProvider from '@/components/renderless/ShowingProvider.vue'

export default {
  props: {
    item: Object
  },

  data: () => ({
    loading: false,
    logs: null
  }),

  created() {
    this.initLogs(this.currentUser.logsWebsocketPath)
  },

  async mounted() {
    await this.$nextTick()
    this.$refs.log.loadData({
      ...this.$refs.log.getDefaultPaginationParams()
    })
  },

  computed: {
    ...mapGetters({
      currentUser: 'user/current'
    }),
  },

  methods: {
    async initLogs(wsURL) {
      let tokens = await this.$auth.getTokensFromRepoOrRefresh()
      this.logs = new CandidatesLogModel({
        candidate: this.item.ID,
        page: [LOG_PAGE.ACCEPTANCE, LOG_PAGE.RESERVATIONS, LOG_PAGE.CONTANCT, LOG_PAGE.CHANGE_LOG],
      }, `${wsURL}?auth_token=${tokens.access}`)
      this.logs.ws.addEventListener("message", this.addToCandidate)
      this.logsShowing = true
    },

    addToCandidate(msg) {
      this.logs.addToCandidate(msg, this.onLogUpdate)
    },

    onLogUpdate(msg) {
      this.$notify({text: msg.comment, type: msg.status, title: msg.candidate.name })
    },

    loadLogsMore() {
    
      this.$refs.log.loadMore({
      })
    },

    
  },

  beforeDestroy() {
    this.logs.ws.removeEventListener("message", this.addToCandidate)
    this.logs.ws.close()
  },
  components: {
    sendLogField,
    logsTabs,
    Logs,
    FullScreenModal,
    ShowingProvider,
    appTextarea: () => import('@/components/global/actions/BaseTextarea.vue'),
    appBtn: () => import('@/components/global/actions/BaseButton.vue'),
  }
}
</script>

<style lang="scss">
.student-log {
  background: #FFFFFF !important;
  .v-list {
    background: #FFFFFF;
  }
  .logs-tab .v-tabs .v-item-group {
    background: #FFFFFF;
    color: #000000;
    .v-tab.v-tab {
      color: #000000;
    }
  }
  .log.log.log {
    margin: 0;
    border-bottom: 1px solid #ECECEC;
    background: #FFFFFF !important;
    color: #232627 !important;
    font-weight: 400;
    font-family: $font;
  }
}

</style>

<style scoped>
.btn-floating {
  bottom: 50px;
}

</style>