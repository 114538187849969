<script>
import CandidatesService from '../core/candidates-service'

export default {
  name: 'CandidatesServiceProvider',

  render() {
    return this.$slots.default
  },

  data: () => ({
    svc: new CandidatesService()
  }),

  provide() {
    return {
      svc: () => this.svc
    }
  },
}
</script>
