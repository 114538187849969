<template lang="pug">
showingProvider(v-slot="showingProvider" @change="resetOnClose")
  .student-card-item
    full-screen-modal(
      v-model="showingProvider.showing"
      @input="showingProvider.updateShowing"
      transparent-header
      no-body-spacing
      :toolbar-color="candidateColor"
    )
      template(#activator)
        v-icon(color="primary" @click.native="showingProvider.toggle") mdi-dots-horizontal
      template(#actions)
        candidate-color(:activeItems="[item]")

      div 
        .info-header(:style="{'background-color': candidateColor}")
          .stunent-avatar-wrapper
            upload-photo(:item="item")
              template(#image="image")
                img(:src="item.course_photo" @click="image.toggle")
              template(#default)
                v-icon(:size="32" v-if="!item.course_photo" color="#007AFF") mdi-image-plus-outline
                <!-- img(v-else :src="item.course_photo") -->
          
          .appmodal-title {{ item.candidateName }}
          .appmodal-descr {{ item.candidateNumber }}
        v-tabs(v-model="activeTab" background-color="#fff" show-arrows).info-tabs
          v-tab(
            v-for="tab in tabs"
            @click="switchTab(tab.value)"
        
            :key="tab.value"
          ).info-tab {{ tab.label }}
        .info-card(v-if="activeTab === 0")
          .info-item
            div
              .info-item__title Exam:
              .info-item__text 
                | {{ item.exam.location }}
                br
                | {{ item.exam.examDate }}
            div(v-if="item.result")
              | {{ item.result.reservation_code}} 
          .info-item
            div
              .info-item__title Phone:
              .info-item__text {{ item.candidateTel  }}
            div
             
              v-icon(@click="copyMoboile(item.candidateTel)").mr-4 mdi-content-copy
              a(:href="`tel:${item.candidateTel}`").call
                v-icon mdi-phone-outline

          .info-item
            div
              .info-item__title Email:
              .info-item__text {{ item.candidateEmail }}
            div
              inform-candidate(:ID="item.exam.id" :messageSentCount="item.exam.message_sent_count")
            
          showingProvider(v-slot="showingProvider")
            candidates-service
              candidate-note(:value="item.notes" :ID="item.ID" 
                @saved="showingProvider.toggle"
                v-slot="{save, input, inputChanged, noteText, updateInput, startEditing}"
              )
                full-screen-modal(
                  v-model="showingProvider.showing"
                  @input="showingProvider.updateShowing"
                  title="Add note"
                )
                  template(#activator)
                    .info-item
                      div
                        .info-item__title Note:
                        .info-item__text {{ noteText | clipString(30) }}
                      div
                        v-icon(@click.native="() => {showingProvider.toggle(); startEditing()} ") mdi-note-outline
                    <!-- v-icon() mdi-note-outline -->
                  div 
                    .appmodal-title {{ item.candidateName }}
                    div.appmodal-descr
                      | {{ item.exam.location }}
                      br
                      | {{ item.exam.examDate }}
                    app-textarea(
                      :value="input" @input="updateInput" hide-details placeholer="notes"
                    )
                    app-btn(color="primary" v2 font-size="16px" block @click.native="save" :disabled="!inputChanged") SEND
          
          .info-item
            div
              .info-item__title Product / Type:
              .info-item__text
                  template(v-if="isPastTab") {{ item.exam.productName }}
                  template(v-else) {{ item.productName }}
            div
              v-chip(small color="#7E1E64" v-if="item.isMijnReservation").text-bold.white--text Mijn
              v-chip(small color="amber lighten-1" v-else).text-bold.white--text Top
          .info-item
            div
              .info-item__title App ID:
              .info-item__text {{ item.applicationId }}
            div
              presentie-status(
                :journey="item.journey_day.id"
                :presentie-status="item.presentie_status"
                :exam="item.exam.id"
                :candidateID="item.ID"
                :class="{'active': candidateCame}"
                v-slot="{togglePresentie}"
              ).student-presentie
                v-icon(v-if="!candidateCame" color="#2CB87D" @click="togglePresentie") mdi-account-outline
                v-icon(v-else color="#fff" @click="togglePresentie") mdi-account-check-outline
          .info-item 
            .info-item__text Send exam results
            candidate-results-email(:item="item" v-slot="{update}")
              v-switch(
                hide-details
                v-model="item.result.send_result_emails"
                @change="update"
              )

        student-log(v-else :item="item")

</template>

<script>
import ShowingProvider from '@/components/renderless/ShowingProvider.vue'
import AppBtn from '@/components/global/actions/BaseButton.vue'
import FullScreenModal from "./CourseFullScreenModal.vue"
import { COURSE_DAY_SIDEBAR_TABS } from '../../core/courseDay-const'
import PresentieStatus from '../journey/CourseDayPresentieStatus.vue'
import { PRESENTIE_STATUS } from '../../core/CourseDayConst'
import CandidateNote from '@/app/admin/modules/candidates/components/candidates/CandidatesNote.vue'
import CandidatesService from '@/app/admin/modules/candidates/components/CandidatesProvider.vue'
import StudentLog from "./CourseDayStudentLog.vue"
import CandidateColor from "./CourseDayColors.vue"
import colorMixin from '@/mixins/color.mixin'
import UploadPhoto from "./CourseDayUploadPhotoMobile.vue"
import informCandidate from '../../../results/components/ResultsInformCandidate.vue'

import copyMixin from '@/mixins/copy.mixin'

export default {
  mixins: [colorMixin, copyMixin],
  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    activeTab: 0,
    tabs: [
      {label: 'INFORMATION', value: 0},
      {label: 'LOGS', value: 1},
    ]
  }),

  computed: {
    isPastTab() {
      return this.$route.query.tab_name === COURSE_DAY_SIDEBAR_TABS.PAST
    },

    candidateCame() {
      return [PRESENTIE_STATUS.CAME, PRESENTIE_STATUS.PARTIALLY_CAME].includes(this.item.presentie_status)

    }
  },

  methods: {
    switchTab(tab) {
      this.activeTab = tab
    },

    resetOnClose() {
      this.activeTab = 0 
    }
  },
  components: {
    FullScreenModal,
    AppBtn,
    ShowingProvider,
    PresentieStatus,
    CandidatesService,
    CandidateNote,
    StudentLog,
    CandidateColor,
    UploadPhoto,
    informCandidate,
    appTextarea: () => import('@/components/global/actions/BaseTextarea.vue'),
    candidateResultsEmail: () => import('@/app/admin/modules/candidates/components/candidates/CandidateResultsEmail.vue'),

    // sendLogField,
    // logsTabs
  }
}
</script>

<style lang="scss" scoped>
.call {
  text-decoration: none;
}
.info-header {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 16px;
}

.stunent-avatar-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: rgba(#007AFF, 0.16);
  img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
}

.fullscreen-body {
  padding: 0;
}

.info-tabs {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.info-tab {
  width: 100%;
}

.info-card {
  background: #FFFFFF;
}



.student-presentie {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  box-shadow: 0 0 24px rgba(22, 22, 22, 0.24);
  &.active {
    background: #2CB87D;
  }
}

</style>
