<template lang="pug">
div.logs-tab
  v-tabs(fixed-tabs v-model="tab" small).log-tab-main
    v-tab(@click="loadAllPages" small) All
    v-tab(@click="loadReserve" small) CBR
    v-tab(@click="loadContact" small) CONTACT
    v-tab(@click="loadChangeLog" small) CHANGE LOG

</template>

<script>
import {LOG_STATUS, LOG_PAGE} from "@/util/const"
export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    loadData: {
      type: Function,
      required: true
    },
    getDefaultPaginationParams: {
      type: Function,
      required: true
    }
  },

  data: () => ({
    tab: 2,
    tabStatus: null
  }),

  methods: {
    loadAllPages() {
      this.model.params.page = [LOG_PAGE.ACCEPTANCE, LOG_PAGE.RESERVATIONS, LOG_PAGE.CONTANCT, LOG_PAGE.CHANGE_LOG]
      this._loadDataHandler()
    },

    _loadDataHandler() {
      this.model.items = []
      this.loadData({...this.getDefaultPaginationParams()})
    },

    loadReserve() {
      this.model.params.page = [LOG_PAGE.RESERVATIONS, LOG_PAGE.ACCEPTANCE]
      this._loadDataHandler()
    },

    loadContact() {
      this.model.params.page = [LOG_PAGE.CONTANCT]
      this._loadDataHandler()
    },

    loadChangeLog() {
      this.model.params.page = [LOG_PAGE.CHANGE_LOG]
      this._loadDataHandler()
    },
  }
}
</script>

<style lang="scss">
.logs-tab .v-tabs .v-item-group{
  background-color: $log-primary;
}

.log-tab-main.v-tabs .v-item-group .v-tab.v-tab {
  color: #fff;
}

.log-tab-secondary.v-tabs .v-item-group .v-tab.v-tab {
  color: $log-default;
  &.v-tab--active {
    color: $primary-color;
  }
} 
</style>